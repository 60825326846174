import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { Navbar } from "./Navbar";
import { LandingPanel } from "./LandingPanel";
import { Categories } from "./Categories";
import { Footer } from "./Footer";
import logo from "../assets/FB PROFILE PHOTO.svg";

import { data } from "../data";

const useStyles = makeStyles((theme: Theme) => ({
  logo: { margin: "auto", width: "100%", height: 150 },
  textStrip: {
    fontFamily: ["Dancing Script", "cursive"].join(","),
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2)
  },
  services: { display: "flex", justifyContent: "center", flexWrap: "wrap" }
}));

export default function App() {
  const classes = useStyles();

  return (
    <>
      <Navbar contact={data.contact} />
      <LandingPanel
        title={data.landing.title}
        slogan={data.landing.slogan}
        description={data.landing.description}
        backgroundURL={data.landing.backgroundImgURL}
      />
      {/* <img src={logo} className={classes.logo} /> */}
      <div className={classes.textStrip}>
        <Typography variant="h4">Our Services</Typography>
      </div>
      <div className={classes.services}>
        {data.categories.map(item => {
          return (
            <Categories
              title={item.title}
              description={item.description}
              imgURL={item.imgURL}
            />
          );
        })}
      </div>
      <Footer
        MC={data.footer.MC}
        DOT={data.footer.DOT}
        social={data.footer.social}
      />
    </>
  );
}
