import React from "react";
import { footerProps } from "./types";
import { Avatar, Typography } from "@material-ui/core";
import { Facebook, Instagram, LinkedIn, Twitter } from "@material-ui/icons";
import { useStyles } from "./styles";

export const Footer = (props: footerProps) => {
  const classes = useStyles();

  return (
    <>
      <footer className={classes.root}>
        <div>
          <Typography align="center" className={classes.text}>
            MC#: {props.MC}
          </Typography>
        </div>
        <div>
          <Typography align="center" className={classes.text}>
            USDOT#: {props.DOT}
          </Typography>
        </div>
        <div className={classes.social}>
          <Avatar
            className={classes.avatar}
            component="a"
            href={props.social.facebook}
          >
            <Facebook className={classes.icon} />
          </Avatar>
          <Avatar
            className={classes.avatar}
            component="a"
            href={props.social.twitter}
          >
            <Twitter className={classes.icon} />
          </Avatar>
          <Avatar
            className={classes.avatar}
            component="a"
            href={props.social.instagram}
          >
            <Instagram className={classes.icon} />
          </Avatar>
          <Avatar
            className={classes.avatar}
            component="a"
            href={props.social.linkedIn}
          >
            <LinkedIn className={classes.icon} />
          </Avatar>
        </div>
      </footer>
    </>
  );
};
