import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: 300,
    height: 150,
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center"
  },
  strip: {
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.primary.main,
    width: "100%"
  },
  title: {
    paddingLeft: theme.spacing(2)
  }
}));
