import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  root: { color: "white" },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  logo: {
    flexGrow: 1,
    height: 75
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));
