import React from "react";

import { Hidden, Paper, Typography } from "@material-ui/core";
import logo from "../../assets/Interrun Logo-01.svg";

import { useStyles } from "./styles";
import { LandingPanelProps } from "./types";

export const LandingPanel = (props: LandingPanelProps) => {
  const classes = useStyles();

  return (
    <Paper
      variant="outlined"
      square
      className={classes.root}
      style={{ backgroundImage: `url(${props.backgroundURL})` }}
    >
      <Paper className={classes.panel}>
        <Hidden xsDown>
          <img src={logo} className={classes.logoLarge} />
        </Hidden>
        <div className={classes.text}>
          {/* <Typography variant="h5" className={classes.title} align="center">
            {props.slogan.toUpperCase()}
          </Typography> */}
          <Hidden smUp>
            <img src={logo} className={classes.logoSmall} />
          </Hidden>
          <Typography align="justify" className={classes.description}>
            {props.description}
          </Typography>
        </div>
      </Paper>
    </Paper>
  );
};
