import main from "../assets/main.jpg";
import brokering from "../assets/brokering.png";
import carriers from "../assets/carriers.jpg";
import shipping from "../assets/international_shipping.jpg";
import dispatching from "../assets/dispatching.jpg";

export const data = {
  contact: { phone: "+14072272346", email: "michelle@interrunlogistics.com" },
  landing: {
    title: "Interrun Logistics",
    slogan: "Excelling freight",
    description:
      "We focuses on excelling your freight from start to finish. We shine in our customer satisfaction by providing on demand updates of your freight as it heads to its final destination.",
    backgroundImgURL: main
  },
  categories: [
    {
      title: "Freight Brokering",
      description: "We offer the best shipping",
      imgURL: brokering
    },
    {
      title: "Dispatching",
      description: "We offer the best couriers",
      imgURL: dispatching
    },
    {
      title: "International shipping",
      description: "We offer the best international shipping",
      imgURL: shipping
    },
    {
      title: "Carriers",
      description: "We offer the best consultations",
      imgURL: carriers
    }
  ],
  footer: {
    MC: "1104781",
    DOT: "3419146",
    social: {
      facebook:
        "https://www.facebook.com/pages/category/Cargo---Freight-Company/Interrun-Logistics-Inc-107256080952580/",
      twitter: "https://twitter.com/InterrunI",
      instagram: "https://www.instagram.com/interrunlogistics/",
      linkedIn: "https://www.linkedin.com/in/michelle-quesada-8302181aa/"
    }
  }
};
