import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // height: 400,
    backgroundSize: "cover",
    backgroundPosition: "center right",
    backgroundRepeat: "no-repeat"
  },
  panel: {
    display: "flex",
    flexDirection: "row-reverse",
    backgroundColor: "#DDD7",
    height: "80%",
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  },
  logoSmall: {
    height: 350,
    width: "100%",
    margin: "auto"
  },
  logoLarge: {
    height: 350,
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15)
  },
  text: {
    position: "relative",
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      borderRight: `1px solid ${theme.palette.text.primary}`
    }
  },
  title: { marginBottom: theme.spacing(4) },
  description: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: theme.spacing(2),
      margin: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2)
    }
  },
  descriptionLarge: {
    position: "absolute",
    bottom: theme.spacing(2),
    margin: theme.spacing(2)
  },
  descriptionSmall: {
    margin: theme.spacing(2)
  }
}));

// "https://www.gallaghertransport.com/wp-content/uploads/freight-forwarder.jpg"
