import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(10),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  text: { fontSize: "0.8em" },
  social: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2)
  },
  avatar: {
    backgroundColor: theme.palette.background.default,
    marginRight: theme.spacing(1),
    height: 50,
    width: 50
  },
  icon: { color: theme.palette.primary.main, fontSize: "1.5em" }
}));
