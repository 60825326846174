import React from "react";

import { Paper, Typography } from "@material-ui/core";

import { useStyles } from "./styles";
import { categoriesProps } from "./types";

export const Categories = (props: categoriesProps) => {
  const classes = useStyles();

  return (
    <Paper
      className={classes.root}
      style={{ backgroundImage: `url(${props.imgURL})` }}
    >
      <div className={classes.strip}>
        <Typography variant="h6" className={classes.title}>
          {props.title}
        </Typography>
      </div>
    </Paper>
  );
};
