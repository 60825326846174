import React from "react";

import { AppBar, Button, Hidden, Toolbar, Typography } from "@material-ui/core";
import { Phone, Email } from "@material-ui/icons";
import { navbarProps } from "./types";
import logoIcon from "../../assets/iLi other INTERRUN.svg";

import { useStyles } from "./styles";

export const Navbar = (props: navbarProps) => {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        <img src={logoIcon} className={classes.logo} />

        <Button
          className={classes.menuButton}
          component="a"
          href={"tel:" + props.contact.phone}
        >
          <Phone className={classes.icon} />
          <Hidden xsDown>Call</Hidden>
        </Button>
        <Button
          className={classes.menuButton}
          component="a"
          href={"mailto:" + props.contact.email}
        >
          <Email className={classes.icon} />
          <Hidden xsDown>Email</Hidden>
        </Button>
      </Toolbar>
    </AppBar>
  );
};
