import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      ,
      "Dancing Script"
    ].join(",")
  },
  palette: { type: "light", primary: { main: green[300] } }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <App />
      </CssBaseline>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
